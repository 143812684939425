import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from "../../components/layout/Layout";
import VideoWrapper from "../../components/VideoWrapper";
import Seo from "../../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
  <Seo title="Dr. Michael Kunkel Colorado Springs - pikespeakoralsurgery" description="Dr. Kunkel’s goal is  to create a highly coordinated oral and maxillofacial surgery facility where the patient’s outcome and satisfaction are the top priority." mdxType="Seo" />
  <section className="container" style={{
        margin: '128px auto'
      }}>
    <VideoWrapper video="NyGng-MjAEU" centered mdxType="VideoWrapper" />
        <h2>{`About Dr. Michael Kunkel`}</h2>
        <p>{`Dr. Michael Kunkel developed a passion for helping others and knew he wanted to be involved in health care from an early age. While originally from Rochester, MN, he first came to Colorado to attend University of Colorado, Boulder and earned his bachelor’s degree in Molecular Biology. He returned to his home state to complete his dental degree at the University of Minnesota and then completed his residency in oral and maxillofacial surgery at Indiana University Medical Center.`}</p>
        <p>{`While earning his undergraduate degree in Boulder, Dr. Kunkel met his wife and learned to love all that Colorado has to offer. He began practicing oral and maxillofacial surgery in Indianapolis following training, but it was always his plan to return to Colorado to be near friends and family and was blessed with the opportunity to do so here in Colorado Springs in 2013.`}</p>
        <p>{`Dr. Kunkel’s goal is to create an office where the patient’s outcome and satisfaction are the top priority and develop the most highly coordinated oral and maxillofacial surgery facility in all of El Paso County. Not only does this mean providing superior clinical treatment to all of his patients but also assembling a team who places the patient at the center of everything that they do. His gentle and compassionate approach to oral surgery ensures that patients are comfortable throughout their procedure.`}</p>
        <p>{`In his spare time, Dr. Kunkel enjoys spending time with his wife, Chelsea, and his son, Jamison. True to his love for Colorado, Dr. Kunkel spends much of his time outdoors exercising and playing sports. He is also an avid movie enthusiast, has a passion for music, and enjoys caring for an impressive collection of fish in his saltwater aquarium.`}</p>
        <p>{`Professional Associations`}</p>
        <ul>
          <li parentName="ul">{`Diplomate, American Board of Oral and Maxillofacial Surgery (ABOMS)`}</li>
          <li parentName="ul">{`American Dental Association (ADA)`}</li>
          <li parentName="ul">{`Colorado Dental Association (CDA)`}</li>
          <li parentName="ul">{`Colorado Springs Dental Society (CSDS)`}</li>
          <li parentName="ul">{`American Association of Oral & Maxillofacial Surgeons (AAOMS)`}</li>
          <li parentName="ul">{`American College of Oral and Maxillofacial Surgeons (ACOMS)`}</li>
          <li parentName="ul">{`Colorado Society of Oral and Maxillofacial Surgery (CSOMS)`}</li>
        </ul>
      </section>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      